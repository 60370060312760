import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';
import ShlifovkaBrevnaSruba from '../images/shlifovka-sruba/shlifovka-brevna-sruba.jpg'
import ShlifovkaDerevyannogSruba from '../images/shlifovka-sruba/shlifovka-derevyannogo-sruba.jpg'
import ShlifovkaSrubaSnaruzhi from '../images/shlifovka-sruba/shlifovka-sruba-snaruzhi.jpg'
import ShlifovkaSrubaPhoto from '../images/shlifovka-sruba/shlifovka-sruba.jpg'
import SrubPosleShlifovki from '../images/shlifovka-sruba/srub-posle-shlifovki.jpg'



const ShlifovkaSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Шлифовка сруба', url:'#', status:true}]

    const galleryImage = [
      {
        src: ShlifovkaBrevnaSruba,
        width: 3,
        height: 2,
        alt: 'шлифовка бревна сруба',
        title: 'шлифовка бревна сруба'
      },
      {
        src: ShlifovkaDerevyannogSruba,
        width: 2,
        height: 3,
        alt: 'шлифовка деревянного сруба',
        title:'шлифовка деревянного сруба'
      },
      {
        src: ShlifovkaSrubaSnaruzhi,
        width: 2,
        height: 3,
        alt: 'шлифовка сруба снаружи',
        title: 'шлифовка сруба снаружи'
      },
      {
        src: ShlifovkaSrubaPhoto,
        width: 2,
        height: 1,
        alt: 'шлифовка сруба фото',
        title:'шлифовка сруба фото'
      },
      {
        src: SrubPosleShlifovki,
        width: 2,
        height: 2,
        alt: 'сруб после шлифовки',
        title: 'сруб после шлифовки'
      }
    ];

  return (
    <div>
        <SEO title="Шлифовка сруба в Калининградской области"
            description="Выполняем работы качественно и в установленные сроки. Работаем по договору, даем гарантии.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Шлифовка сруба в Калининград&shy;ской области'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-center text-md-left">Шлифовка сруба</h1>
                <p>Шлифовка сруба из бруса или бревна – обязательный процесс отделки стен деревянных домов. 
                  Подобная процедура позволяет удалить с поверхности все неровности и различные дефекты.
                    Шлифовка дома из дерева является процессом не только эстетически, но и практически важным.
                </p>
                <Advantages /> 
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="price-table">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по шлифовке сруба сруба</h2>
                <Col md="12" className="mt-2">
                  <table className="table table-striped p-table">
                  <thead>
                    <tr>
                      <th scope="col">№</th> 
                      <th scope="col">Услуга</th>
                      <th scope="col">Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Чистка водой под давлением с применением химии</td>
                    <td>от <span className="price-list">50</span> рублей за м<sup><small>2</small> </sup></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Механическая чистка с применением инструмента, включая торцы</td>
                    <td>от <span className="price-list">350</span> рублей за м<sup><small>2</small> </sup></td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Заделка межвенцовых швов и трещин бревна герметиком под покраску</td>
                    <td>от <span className="price-list">165</span> рублей за м<sup><small>2</small> </sup></td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Пескоструй бревна от 200 м<sup><small>2</small></sup>, заделка межвенцовых швов герметиком (ровный шов)</td>
                    <td>от <span className="price-list">600</span> рублей за м<sup><small>2</small> </sup></td>
                  </tr>
                  </tbody>
                </table>
                </Col>
            </Row>
          </Container>
         </Container>
         <Container className="mt-5">
           <h3>Правильное выполнение работы позволяет:</h3>
            <Row>
              <ul>
                <li>гниение;</li>
                <li>появление плесени и грибков, разрушающих природный строительный материал;</li>
                <li>потемнение;</li>
                <li>неровности бревен;</li>
                <li>воздействие влаги и насекомых.</li>
                <li>эстетичный внешний вид</li>
                <li>гладкие стены и отсутствие неровностей на поверхности</li>
            </ul>
            <p>Шлифовка требуется независимо от  типа бревна из которого построен дом. 
              Будь то клееный брус, рубленный брус или бревно - шлифовка улучшит внешний вид и продлит время эксплуатации вашего дома.
            </p>
            </Row>
         </Container>
         <FormQuickOrder className="mt-3" />
         <Container className="mt-5">
         <h2>Когда проводят шлифовку сруба</h2>
           <Row>
             <Col md="12">
              <p>Проводить шлифовальные работы нужно, соблюдая определенные условия. 
                В противном случае можно нанести бревнам вред, не получив желаемого результата. 
                Главные и неоспоримые условия:</p>
                <ul>
                  <li><span className="price-list">Угасание усадки.</span> 
                  Нельзя шлифовать сруб сразу после строительства. Эффективность такого мероприятия будет очень низкой, ведь в последующие 1,5-2 года сруб будет усыхать и изменять свои геометрические параметры.</li>
                  <li><span className="price-list">Высыхание древесины.</span> 
                  Шлифовать влажный сруб нельзя, это приведёт к задиранию и затирке наружного слоя древесины. Влажность дерева должна быть меньше 20 %, только в этом случае шлифовка будет эффективной.</li>
                  <li><span className="price-list">Положительные температуры воздуха при шлифовке.</span> 
                  В зимний период при пониженных температурах срубы не шлифуют. Сразу по окончанию шлифовки бревна нужно обработать антисептиками, а делать это рекомендуется в тёплое время.</li>
                </ul>
             </Col>
           </Row>
           <Row>
             <h2>Шлифуем срубы в соответствии с технологией</h2>
             <Col md="12" >
              <p>Производить шлифовку бруса необходимо после полной его усадки.  
                  этом влажность дерева должна в идеале составлять 19%, ведь в противном случае в 
                  дальнейшем обработанный сруб может деформироваться и утратить свою целостность. 
                  Для измерения уровня влажности используется влагомер.
                </p>
                <p>В современном строительстве выделяют шлифовку сруба в один и в два слоя. 
                  В первом случае с поверхности бревен устраняются следы использования рубанка, 
                  плесень и прочие загрязнения. Шлифовка в два слоя предполагает полировку бревен с использованием мелкой шкурки. 
                  Шлифовка в один слой актуальна для обработки наружных стен дома, в два слоя – для внутренних.
                </p>
                <ul>
                  <li>Перед шлифованием сруба необходимо его подготовить:</li>
                  <li>Стены сруба осматриваются на дефекты, </li>
                  <li>Убираются крупные куски древесины, </li>
                  <li>Производится дефектовка конопатки,</li>
                  <li>Щели и трещины замазываются герметиком.</li>
                  <li>Стены делятся на участки, по очередности шлифования их обрабатывают.</li>
                </ul>
             </Col>
           </Row>
         </Container>
        <ImagesView galleryImage={galleryImage} />
        <Container className="mt-5">
           <h2>Этапы работ по шлифовке сруба</h2>
            <h4>Процесс шлифовки включает в себя такие шаги, как:</h4>
          <Row>
            <Col className="md-12">
              <span className="price-list">Черновое шлифование;</span><br />
              <p>Первичное шлифование сруба крупнозернистыми насадками.
                  В процессе работ дерево покрывается «ворсом», убирается синева, поражённые плесенью и грибком участки, 
                  если таковые имеются.
              </p>

              <span className="price-list">Средняя обработка;</span><br />
              <p>Появившийся в процессе черновой шлифовки «ворс» удаляется. Сруб приобретает симпатичный вид, бревно становится гладким.</p>

              <span className="price-list">тонкая обработка;</span><br />
              <p>На этом этапе нужно работать стамеской, шлифуя недоступные инструменту места сруба, 
                доводя его практически до состояния нового</p>

              <span className="price-list">Заключительное шлифование перед нанесением лака.</span><br />
              <p>Практически полировка дерева до идеально гладких стен. Особое внимание обращается на стыки и труднодоступные места. 
                Обязательно перед нанесением лакокрасочных покрытий.</p>
            </Col>
          </Row>
          <Row>
            <h2>Профессиональные услуги по окраске сруба в Калининграде.</h2>
            <Col md="12">
              <p>Компания «ГрутВуд» выполняет все виды работ по шлифовке и покраске срубов. Мы используем только проверенные материалы, прошедшие необходимую сертификацию. Все работы проводятся с обязательным соблюдением технологии и температурного режима. 
              </p>
              <p> 
              Оказывая услуги по шлифовке срубов на протяжении семи лет мы накопили обширный опыт работы с древесиной, 
              что позволяет нам качественно и в срок выполнять любые объемы работ. 
              Более индивидуальные вопросы вы можете уточнить у наших специалистов.
              </p>
            </Col>
          </Row>
        </Container>
        <OfficeAtMap />
      </Layout>
    </div>
  );
};

export default ShlifovkaSruba;